import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"


const AboutPage = () => (
  <Layout subtitle="About">
    <Seo title="About" />

    <p>
Knockout Cats produces scripted female vs. female <Link to="/videos">fight videos</Link> starring some of the most <Link to="/fighters">attractive ladies</Link> to perform in this genre. The action is generally comprised of catfights, wrestling, fistfights, submissions, and knockout holds. The safety of our models is paramount so you won't see high-risk maneuvers here (that's what WWE is for), but the action, storylines, and models themselves are always entertaining.
    </p>

    <div className="center">
      <StaticImage
        src="../images/knockoutcats_title.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Knockout Cats"
        style={{ marginBottom: `1.45rem` }}
      />
    </div>

    <p>
Knockout Cats does <b>not</b> create custom videos for the following reasons:
    </p>

   <ol>
     <li>It requires additional coordination between myself and a third party (i.e. you).</li>
     <li>I have no confidence in my ability to accurately turn your words into exactly what you envision.</li>
     <li>I'd stress out about 1. & 2., and my primary purpose for producing videos is to have fun. (stress &#8800; fun)</li>
     <li>I film and edit videos at sporadic intervals so turnaround times for delivery of customs would be horrendous.</li>
   </ol>

    <p>
Although Knockout Cats is run by a single guy, the site would be nothing without the efforts of <Link to="/fighters">the lovely fighters</Link> who star in the videos. I'm forever grateful for the work that they do and their willingness and patience in dealing with me.
    </p>

    <p>
If you'd like to learn more about the site and its creator, checkout out the <Link to="/blog">site blog</Link> where you'll find articles on a fairly eclectic collection of topics.
    </p>

    <p>
For any issues with the site or for general questions, please contact me at <a href="mailto:admin@knockoutcats.com">admin@knockoutcats.com</a>.
    </p>

  </Layout>
)

export default AboutPage
